<template>
  <div>
    <van-nav-bar
      title="基本情况"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="basicBox" v-if="this.houseForm.householderCode">
      <div class="codeNum">
        <div>户编码</div>
        <div>{{ houseForm.householderCode }}</div>
      </div>
      <div class="codeNum">
        <div>姓名</div>
        <div>{{ houseForm.name }}</div>
      </div>
      <div class="codeNum">
        <div>户类型</div>
        <div>
          {{
            common.gettypeListLabelFn(
              "householdTypeList",
              houseForm.householdCategory
            )
          }}
        </div>
      </div>
      <div class="codeNum">
        <div>联系电话</div>
        <div>{{ houseForm.phone || houseForm.contactNumber }}</div>
      </div>
      <div class="codeNum">
        <div>证件号码</div>
        <div>
          {{
            houseForm.disabilityCard
              ? houseForm.disabilityCard
              : houseForm.idCard
          }}
        </div>
      </div>
      <div class="codeNum">
        <div>家庭人口数</div>
        <div>{{ houseForm.populationNumber }}</div>
      </div>
      <div class="codeNum">
        <div>家庭劳动力人口数</div>
        <div>{{ houseForm.laborNumber }}</div>
      </div>
      <div class="codeNum">
        <div>务工人数</div>
        <div>{{ houseForm.workerNumber }}</div>
      </div>
      <div class="codeNum">
        <div>在校生人数</div>
        <div>{{ houseForm.studentNumber }}</div>
      </div>
      <div class="codeNum">
        <div>残疾人数</div>
        <div>{{ houseForm.disabilityNumber }}</div>
      </div>
      <div class="codeNum">
        <div>重大疾病人数</div>
        <div>{{ houseForm.majorIllnessNumber }}</div>
      </div>
      <div class="codeNum">
        <div>慢性病人数</div>
        <div>{{ houseForm.chronicIllnessNumber }}</div>
      </div>
    </div>
    <div class="opinion" @click="goOpinion">意见及诉求</div>
  </div>
</template>

<script>
import { findtroubleshootFamily } from "@/api/atp.js";
export default {
  data() {
    return {
      houseForm: {},
      phoneNum: "",
      searchObj: {
        idCard: JSON.parse(sessionStorage.getItem("postForm")).idCard,
        // idCard: "110101199003075330",
      },
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      findtroubleshootFamily(this.searchObj)
        .then((res) => {
          if (res.status === 200) {
            this.houseForm = res.data;

            let token = sessionStorage.getItem("token");
            if (!token) {
              // 姓名隐藏
              if (this.houseForm.name.length == 3) {
                this.houseForm.name =
                  this.houseForm.name.substring(0, 1) +
                  "*" +
                  this.houseForm.name.substring(
                    this.houseForm.name.length - 1,
                    this.houseForm.name.length
                  );
              } else if (this.houseForm.name.length == 2) {
                this.houseForm.name = this.houseForm.name.substring(0, 1) + "*";
              }
              // 证件号码隐藏
              var idCardStr = "";
              for (var i = 0; i < this.houseForm.idCard.length - 8; i++) {
                idCardStr += "*";
              }
              this.houseForm.idCard =
                this.houseForm.idCard.substring(0, 4) +
                idCardStr +
                this.houseForm.idCard.substring(
                  this.houseForm.idCard.length - 4,
                  this.houseForm.idCard.length
                );
              // 手机号码隐藏
              for (var j = 0; j < this.houseForm.phone.length - 7; j++) {
                this.phoneNum += "*";
              }
              this.houseForm.phone =
                this.houseForm.phone.substring(0, 3) +
                this.phoneNum +
                this.houseForm.phone.substring(
                  this.houseForm.phone.length - 4,
                  this.houseForm.phone.length
                );
            }
          } else {
            this.$toast("查询数据失败");
          }
        })
        .catch(() => {
          // this.$toast("获取基本信息失败");
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goOpinion() {
      this.$router.push("/opinion");
    },
  },
};
</script>

<style scoped>
.basicBox {
  margin-bottom: 0.5rem;
}
.basicBox > div {
  border-bottom: 1px solid #eee;
  width: 94%;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.basicBox > div > div {
  line-height: 0.5rem;
}
.opinion {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: #f0ffff;
  color: #1e90ff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
.van-nav-bar__content {
  height: 100%;
}
.van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
.van-icon-arrow-left,
.van-nav-bar__text {
  font-size: 0.14rem;
}
</style>